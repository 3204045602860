<template>
    <layout name="StudentLayout">
        <div class="Home">
            <section class="uk-section uk-padding-remove">
                <div class="uk-container">
                    <div class="uk-table uk-table-striped uk-table-responsive">
                        <colgroup>
                            <col width="13%">
                            <col width="21%">
                            <col width="12%">
                            <col width="21%">
                            <col width="12%">
                            <col width="21%">
                        </colgroup>
                        <tr >
                            <td class="subtit">
                                <span class=" uk-text-danger" >Exam Name</span>
                            </td>
                            <td>
                                <input type="text" placeholder="Exam name"
                                    class="uk-input" />
                            </td>
                            <td class="subtits">Description</td>
                            <td>
                                <input type="text"  placeholder="Mock, Active, Practice test"
                                    class="uk-input" />
                            </td>
                        </tr>
                        <tr >
                            <td class="subtit">
                                <span class=" uk-text-danger" >Exam Name</span>
                            </td>
                            <td>
                                <input type="text" placeholder="Exam name"
                                    class="uk-input" />
                            </td>
                            <td class="subtits">Description</td>
                            <td>
                                <input type="text"  placeholder="Mock, Active, Practice test"
                                    class="uk-input" />
                            </td>
                        </tr>
                        <tr >
                            <td class="subtit">
                                <span class=" uk-text-danger" >Exam Name</span>
                            </td>
                            <td>
                                <input type="text" placeholder="Exam name"
                                    class="uk-input" />
                            </td>
                            <td class="subtits">Description</td>
                            <td>
                                <input type="text"  placeholder="Mock, Active, Practice test"
                                    class="uk-input" />
                            </td>
                        </tr>
                    </div>
                </div>
            </section>
        </div>
    </layout>
</template>
<script>
import Layout from '../../layouts/Layout'
export default {
    name: 'Home',
    components: {
      Layout,
    },
    created(){},
    data(){
        return{

        }

    },
    methods: {

    }

}
</script>
<style scoped>
.uk-table td.subtit {
  border-right: 1px solid #dee2fe;
  text-align: center;
  font-weight: bold;
  color: #404348;
  padding: 20px 0px;
  font-size: 14px;
  background-color: #eeeeef;
}

.uk-table td.subtits {
  border-right: 1px solid #dee2fe;
  border-left: 1px solid #dee2fe;
  font-weight: bold;
  padding: 20px 0px;
  padding-left: 10px;
  color: #404348;
  font-size: 14px;
  background-color: #eeeeef;
}
</style>
